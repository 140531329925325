import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import { CATEGORIES_ROUTE } from "@routes";

import GridList from "components/GridList";
import PageTitle from "components/PageTitle";

import ProductItem from "templatesComponents/Category/ProductItem";

import BlankPage from "./BlankPage";
import PrivateLayout from "layouts/PrivateLayout";

const Subcategory = ({ data }) => {
  const { title } = data.contentfulSubcategory;

  const productsBySubcategories = data.allContentfulProductBySubcategories
    ? data.allContentfulProductBySubcategories.edges
    : [];
  const productsBySubcategory = data.allContentfulProductBySubcategory
    ? data.allContentfulProductBySubcategory.edges
    : [];

  const products = [...productsBySubcategories, ...productsBySubcategory];

  if (products.length === 0) {
    return (
      <BlankPage title={title} link="Volver a categorías" to={CATEGORIES_ROUTE}>
        Oops, parece que aún no hay productos en esta categoría.
      </BlankPage>
    );
  }

  return (
    <PrivateLayout metaTitle={title}>
      <PageTitle title={title} />
      <GridList>
        {products.map(({ node }) => (
          <ProductItem
            key={node.slug}
            {...node}
            image={<Img fixed={node.images[0].fixed} />}
          />
        ))}
      </GridList>
    </PrivateLayout>
  );
};

Subcategory.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Subcategory;

export const query = graphql`
  query ($id: String!) {
    contentfulSubcategory(id: { eq: $id }) {
      title
    }
    allContentfulProductBySubcategories: allContentfulProduct(
      filter: { subcategories: { elemMatch: { id: { eq: $id } } } }
      sort: { order: DESC, fields: parsedUpdatedAt }
    ) {
      edges {
        node {
          title
          slug
          price
          images {
            fixed(width: 276, quality: 100) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
          items {
            title
          }
        }
      }
    }
    allContentfulProductBySubcategory: allContentfulProduct(
      filter: { subcategory: { id: { eq: $id } } }
      sort: { order: DESC, fields: parsedUpdatedAt }
    ) {
      edges {
        node {
          title
          slug
          price
          images {
            fixed(width: 276, quality: 100) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
          items {
            title
          }
        }
      }
    }
  }
`;
