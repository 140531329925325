import React from "react";
import PropTypes from "prop-types";

import { StyledLink, Title, Price } from "./ProductItem.styles";

import { getProducItems } from "utils/getProducItems";

import withLocation from "components/withLocation";
import { getProductDefaultQuantity } from "utils/getProductDefaultQuantity";

const ProductItem = ({ image, title, price, slug, location, items }) => {
  const productItems = getProducItems(items);

  const productDefaultQuantity = getProductDefaultQuantity(productItems);

  const selectedProductItem = productItems.find((productItem) => {
    return productItem.quantity === productDefaultQuantity;
  });

  const selectedProductItemPrice =
    selectedProductItem !== undefined ? selectedProductItem.price : price;

  return (
    <StyledLink to={`${location.pathname}/${slug}`}>
      {image}
      <Title>{title}</Title>
      <Price>{selectedProductItemPrice.toFixed(2)}&euro;</Price>
    </StyledLink>
  );
};

ProductItem.propTypes = {
  image: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  items: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default withLocation(ProductItem);
