import styled from "@emotion/styled";

export const GridContainer = styled.ul`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;

  list-style-type: none;

  padding: 0;
  margin-top: 26px;

  @media (min-width: 1000px) {
    justify-content: flex-start;
  }
`;

export const GridItem = styled.li`
  width: 276px;

  > * {
    max-width: 100%;
  }

  text-align: center;
  background-color: #fff;

  margin-bottom: 34px;

  overflow: hidden;

  @media (min-width: 580px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    margin-left: 22px;
    margin-right: 22px;
  }
`;
