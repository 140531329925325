import React from "react";
import PropTypes from "prop-types";

import { GridContainer, GridItem } from "./GridList.styles";

const GridList = ({ children }) => (
  <GridContainer>
    {children.map((child, index) => (
      <GridItem key={`grid-list-item-${index}`}>{child}</GridItem>
    ))}
  </GridContainer>
);

GridList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default GridList;
