import styled from "@emotion/styled";
import { Link } from "gatsby";

export const StyledLink = styled(Link)`
  display: inline-block;
  color: ${(props) => props.theme.colors.black};
  text-decoration: none;
`;

export const Title = styled.h3`
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 9px;
`;

export const Price = styled.span`
  font-size: 24px;
`;
